.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  gap: 1vh;
  background-color: rgb(50, 50, 50);
  color: white;
}

.Image {
  background-color: rgb(215, 215, 215);
  border-radius: 10px;
  padding: 1vw;
  width: 20vw;
}

img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.Decision {
  text-align: left;
  background-color: rgb(80, 80, 80);
  width: 75vw;
  padding: 3vh;
  border-radius: 20px;
  white-space: pre-wrap;
  font-family: 'Kode Mono';
  font-size: 16px;
  line-height: 35px;
}

.Choices {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: rgb(28, 28, 28);
  border-radius: 15px;
  padding: 2vh;
  max-width: 50vw;
}

.Choice {
  background-color: rgb(125, 125, 125);
  border-radius: 25px;
  padding: 1vw;
  margin: 0.75vw;
  white-space: pre-wrap;
}

.Choice:hover {
  background-color: rgb(200, 200, 200);
  cursor: pointer;
}